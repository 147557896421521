
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { bannerTypes } from '@/constants';

import { Location } from '@/entities/Location';
import { Image } from '@/entities/images/Image';
import VideosList  from '@/components/lists/VideosList.vue';
import MediaList from '../lists/MediaList.vue';
import { Video } from '@/entities/videos/Video';

@Component({
  computed: mapGetters([
    'location'
  ]),
  components: {
    VideosList,
    MediaList
  },
  data: () => ({
    video: null,
    image: null,
  }),
})
export default class BannerButton extends Vue{

  @Prop({ default: "" })
  btnType!: string

  @Prop({ default: "" })
  asset!: string;

  @Prop({ default: () => null })
  setimage!: Function;

  @Prop({ default: "" })
  buttonURL!: string;

  @Prop({ default: () => (bannerTypes) })
  types!: string[];

  @PropSync("btnType", {required: true, default:""})
  btnTypeSynced!: string;

  @PropSync("buttonURL", {required: true, default:""})
  btnURLSynced!: string;

  //Todo change on page load
  selectedImage: number = -1;

  image?: Image | null;
  // images: Image[] = [];

  video?: Video | null;

  location!: Location;
  //TODO Image not Shown as selected on page load
  // mounted() {
  //   this.loadImages().then(() => {
  //     this.selectedImage = this.images.findIndex(img => img.file_url === this.btnURLSynced);

  //   })

  // }


  typeChanged() {
    this.image = undefined;
    this.video = undefined;
    this.$emit('update:buttonURL', undefined);
  }

  setImageURL(img?: Image | null) {
    this.image = img
    this.$emit('update:buttonURL', this.image != null ? `${this.image.file_url}` : undefined);
  }

  setVideoURL(video?: Video | null) {
    this.video = video;
    this.$emit('update:buttonURL', (this.video != null ? `${this.video.file_url}` : undefined));
  }

  /**
   * Load images available
   */
  // async loadImages() {
  //   this.$images.list().then((images) => {
  //     this.images = images;
  //     this.images = this.images.filter((image) => {
  //       if (image.category.toLowerCase() !== "banner") {
  //         return false;
  //       }

  //       if (image.location?.uuid !== this.location.uuid) {
  //         return false;
  //       }

  //       return true;
  //     });

  //   });
  // }

  // imageChanged(){
  //   if (this.selectedImage === -1 || this.selectedImage == undefined) {
  //     this.image = undefined;
  //     this.$emit('update:buttonURL', "");
  //   }
  //   else{
  //     this.image = this.images[this.selectedImage];
  //     this.$emit('update:buttonURL', this.image != null ? `${this.image.file_url}` : undefined);
  //   }
  // }


}
