
import { Kokote } from './devices/Kokote';

export class CombinedRooms{
    room_number: number;
    rooms: Kokote[];

    constructor(room_number: number, rooms: Kokote[]) {
        this.room_number = room_number;
        this.rooms = rooms;
    }
}
export interface SplittedItem {
    room_number: number;
    room_letter: string;
    room: Kokote;
  }