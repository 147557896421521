
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { categories } from '@/constants';
import { Video } from '@/entities/videos/Video';

@Component({

})
export default class VideosList extends Vue {
  @Prop({ required: true })
  value!: Video;

  @Prop({ default: false })
  filter!: boolean;

  @Prop({default: ""})
  videoURL!: string;
  search: string = "";
  videos: Video[] = [];

  categories: string[] = categories;
  selectedCategories: string[] = [];

  videoIndex: number = -1;


  set video(value: Video | undefined) {
    this.$emit('input', value)
    console.log("video set");
  }

  get video() {
    return this.value;
  }

  testClicked(){
    if (this.videoIndex === -1 || this.videoIndex === undefined) {
      this.video = undefined;
    }
    else{
      this.video = this.videos[this.videoIndex];
    }
  }

  videoClicked(value: Video) {
    this.video = value;
  }


  mounted() {
    this.fetchVideos().then(() => {
      this.videoIndex = this.videos.findIndex(vid => vid.file_url === this.videoURL);
    });

  }

  async fetchVideos() {
    await this.$videos.list().then(videos => {
      this.videos = videos;
    });
  }

  get filtered() {
    return this.$helpers.filterVideos(this.videos, this.search);
  }
}
