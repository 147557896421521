import { Location } from "../Location";
import { Room } from "./Room";

export enum CustomMediaType {
  NONE = "None",
  IMAGE = "Image",
  VIDEO = "Video",
}


export interface Kokote {
  id: number;
  created_at: Date;
  updated_at: Date;
  serial_number: string;
  mac_address: string;
  version: number;
  lan_ip: string;
  apps_installed: string;
  hdmi_plugged: boolean;
  uptime: Date;
  kosuptime: Date;
  cpu_usage: string;
  memory_usage: string;
  temp: number;
  tv_brand: string;
  tv_model: string;
  screen_state: boolean;
  ir_exists: boolean;
  ir_version: string;
  hardware_version: number;
  room?: Room;
  location: Location;
  backupwifi_running: boolean;
  backupwifi_activate: boolean;
  lock_device: boolean;
  custom_media_url: string;
  custom_media_type: string;
  boot_on_tv: boolean;
  tv_channel: string;
}
