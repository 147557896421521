
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { categories } from '@/constants';
import { Video } from '@/entities/videos/Video';
import { Image } from '@/entities/images/Image';

type Media = Video | Image;

@Component({

})
export default class MediaList extends Vue {
  @Prop({ required: true })
  value!: Media;

  @Prop({ default: false })
  filter!: boolean;

  @Prop({ required: true })
  type!: string;

  @Prop({default: ""})
  mediaURL!: string;
  search: string = "";
  medias: Media[] = [];

  categories: string[] = categories;
  selectedCategories: string[] = [];

  mediaIndex: number = -1;


  set media(value: Media | undefined) {
    this.$emit('input', value)
    console.log("media set");
  }

  get media() {
    return this.value;
  }

  testClicked(){
    if (this.mediaIndex === -1 || this.mediaIndex === undefined) {
      this.media = undefined;
    }
    else{
      this.media = this.medias[this.mediaIndex];
    }
  }

  mediaClicked(value: Media) {
    this.media = value;
  }


  mounted() {
    this.fetchMedias().then(() => {
      this.mediaIndex = this.medias.findIndex(media => media.file_url === this.mediaURL);
    });

  }

  async fetchMedias() {
    if (this.type === "image") {
      await this.fetchImages();
    }
    else if (this.type === "video") {
      await this.fetchVideos();
    }

  }

  async fetchImages() {
    await this.$images.list().then(images => {
      this.medias = images;
    });
  }
  async fetchVideos(){
    await this.$videos.list().then(videos => {
      this.medias = videos;
    });
  }

  src(item: Media) {

    if (this.type === "image") {
      return (item as Image).file_url;
    }
    else if (this.type === "video") {
      return (item as Video).preview_url || (item as Video).file_url;
    }
    else {
      return "";
    }
  }

  get filtered(): Video[] | Image[] | Media[] {
    let result = this.$helpers.filterMedias(this.medias, this.search);
    if (this.type === "image") {
      return result as Image[];
    }
    else if (this.type === "video") {
      return result as Video[];
    }
    else {
      return result;
    }
  }
}
